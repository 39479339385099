import React from "react";

const Contact = () => {
  return (
    <>
      <header
        className="pages-header bg-img valign"
        data-background="/img/contact/background.jpg"
        data-overlay-dark="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cont text-center">
                <h1>Contact Us</h1>
                <div className="path">
                  <a href="/">Home</a>
                  <span>/</span>
                  <a href="#0" className="active">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="contact">
        <div className="info bg-gray pt-80 pb-80">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="item">
                  <span className="icon pe-7s-phone"></span>
                  <div className="cont">
                    <h6 className="custom-font">Call Us</h6>
                    <p>
                      <a href="tel:917339477963">+91 73394 77963</a>
                    </p>
                    <p>
                      <a href="tel:919008043596">+91 90080 43596</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <span className="icon pe-7s-mail-open"></span>
                  <div className="cont">
                    <h6 className="custom-font">Email Us</h6>
                    <p>
                      <a href="mailto:info@noeticarchitects.com">
                        info@noeticarchitects.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="item">
                  <span className="icon pe-7s-map"></span>
                  <div className="cont">
                    <h6 className="custom-font">Address</h6>
                    <p>#133/2, 13th main, </p>
                    <p>3rd cross, H.A.L 3rd stage, </p>{" "}
                    <p>Kodihalli, Bangalore,</p>
                    <p>Pin: 560 008.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 map-box">
              <div className="map" id="ieatmaps"></div>
            </div>
            <div className="col-lg-6 form">
              <form
                id="contact-form"
                method="post"
                action=""
              >
                <div className="messages"></div>

                <div className="controls">
                  <div className="form-group">
                    <input
                      id="form_name"
                      type="text"
                      name="name"
                      placeholder="Name"
                      required="required"
                    />
                  </div>

                  <div className="form-group">
                    <input
                      id="form_email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      required="required"
                    />
                  </div>

                  <div className="form-group">
                    <textarea
                      id="form_message"
                      name="message"
                      placeholder="Message"
                      rows="4"
                      required="required"
                    ></textarea>
                  </div>

                  <button type="submit" className="btn-curve btn-color">
                    <span>Send Message</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default Contact;
