import React from "react";
import _ from "lodash";
import { portfolio } from "../data/portfolio";

const Portfolio = () => {
  return (
    <>
      <header
        className="pages-header bg-img valign"
        data-background="/img/contact/background.jpg"
        data-overlay-dark="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cont text-center">
                <h1>Portfolio</h1>
                <div className="path">
                  <a href="/">Home</a>
                  <span>/</span>
                  <a className="active" href="#0">
                    Portfolio
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="portfolio section-padding">
        <div className="container-fluid">
          <h6 className="text-center">
            <i>
              <q>
                Architecture is the learned game, correct and magnificent, of
                forms assembled in the light.
              </q>{" "}
              – Le Corbusier
            </i>
          </h6>
          <div className="row mt-50">
            <div className="filtering text-center col-12">
              <div className="filter box">
                <span data-filter="*" className="active">
                  All
                </span>
                <span data-filter=".architecture">Architecture</span>
                <span data-filter=".interior">Interior</span>
                <span data-filter=".landscape">Landscape</span>
              </div>
            </div>
            <div className="gallery twsty inf-lit full-width">
              {_.map(_.shuffle(portfolio), (item, index) => {
                return (
                  <div
                    className={"items four-column mt-50 " + item.type}
                    key={index}
                  >
                    <div
                      className="item-img bg-img"
                      data-background={item.cover}
                    >
                      <a href={`/portfolio/${item.type}/${item.slug}`}>
                        <div className="item-img-overlay valign">
                          <div className="location">
                            <h6>{item.location}</h6>
                            <h6>{item.builduparea}</h6>
                          </div>
                          <div className="status">
                            <h6>{item.status}</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="info mt-10">
                      <h5>{item.title}</h5>
                      <span>{item.subtitle}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;
