import React from "react";
import _ from "lodash";
import { portfolio } from "../data/portfolio";

const Index = () => {
  const filterPortfolio = _.filter(portfolio, (obj) => obj.homepage);

  return (
    <>
      <section id="about" className="about section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 valign">
              <div
                className="exp-content nopat wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h6 className="sub-title">About Us</h6>
                <h2 className="mb-20 playfont">
                  Challenging Today <br />
                  Reinvent Tomorrow
                </h2>
                <p className="mb-10">
                  <b>Noetic Architects</b> is a Bangalore based firm founded by{" "}
                  <b>Ar.Paramesh Gopal & Ar.Monisha Radhakrishnan</b> in the year 2018. We
                  are a bunch of curious minds who strive to bring up simple and
                  efficient design solutions that challenge today and reinvent
                  tomorrow. Working with different communities in a distinctive
                  approach to bring life to the space and deliver unforgettable
                  experiences.
                </p>
                <p>
                  We follow a simple and clean approach to design. Our main
                  focus is to create rational and timeless designs that can
                  reduce the impact on the environment as much as possible.
                </p>
                <ul>
                  <li>Architecture</li>
                  <li>Interior Design</li>
                  <li>Landscape</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ab-exp">
                <div className="row">
                  <div className="col-md-12 wow fadeInUp" data-wow-delay=".3s">
                    <div className="img mb-20 wow imago">
                      <h6 className="sub-title">PRINCIPAL ARCHITECT</h6>
                      <p>
                        <b>Ar.Paramesh Gopal</b> graduated from Anna University,
                        Chennai. He began his professional career by working
                        with multiple Architects and firms in and around
                        Bangalore. With all the gained experience and knowledge,
                        he kick-started Noetic Architects in 2018 which has now
                        grown into a team of like-minded professionals. He truly
                        believes that new heights can be reached only by
                        dedication and commitment to work.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-7 wow fadeInUp" data-wow-delay=".3s">
                    <div className="img wow imago">
                      <img src="/img/about/about.jpg" alt="" />
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="years-exp">
                      <div className="exp-text">
                        <h2 className="custom-font">3</h2>
                        <h6>Years Of Experience</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-95">
            <div className="col-lg-7 valign">
              <div
                className="exp-content nopat wow fadeInUp"
                data-wow-delay=".3s"
              >
                <h6 className="sub-title">Vision</h6>
                <h2 className="mb-20 playfont">Our Vision</h2>
                <p className="mb-10">
                  We believe in functional and meaningful designs that serves
                  the need of the clients. Also, the design of a building should
                  relate to its environment in a unique way. We follow a
                  holistic approach to design where all the aspects of design
                  are inter-connected. Architecture, Interior design, Landscape
                  design, Services etc, goes hand in hand.
                </p>
                <p>
                  We provide complete design of buildings across a range of
                  sectors by following a sensible approach to design, depending
                  on the local climate and available materials. With the
                  increasing environmental pollution, it’s our responsibility to
                  provide sustainable designs that doesn’t compromise the
                  future.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="ab-exp">
                <div className="row">
                  <div className="col-md-12 wow fadeInUp" data-wow-delay=".3s">
                    <div className="img wow imago">
                      <img src="/img/about/vision.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services" className="services section-padding bg-gray">
        <div className="container">
          <div className="section-head text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <h6 className="custom-font wow fadeInDown" data-wow-delay=".3s">
                  Best Features
                </h6>
                <h4 className="playfont wow flipInX" data-wow-delay=".5s">
                  Our Services
                </h4>
              </div>
            </div>
          </div>
          <div
            className="row row-eq-height bord-box bg-img wow fadeInUp"
            data-wow-delay=".3s"
            data-background="/img/services/our-services.jpg"
          >
            <div className="col-lg-4 col-md-6 item-bx">
              <h2 className="custom-font numb">01</h2>
              <h6 className="mb-20">Architecture</h6>
              <q>Recognising the need is the primary condition for design</q> –
              Charles Eames
              <p>
                The answer to any problem always lies in the question itself.
                Each design is unique to its requirement and site condition. We
                follow a simple contemporary style of design. We don’t believe
                in meaningless luxurious designs & elements.
              </p>
            </div>

            <div className="col-lg-4 col-md-6 item-bx">
              <h2 className="custom-font numb">02</h2>
              <h6 className="mb-20">Interior Design</h6>
              <p>
                The Interior design is an extension of the Architecture itself.
                We create interiors that compliments the concept of the built
                and as well as focus on the efficient usage of the space.
              </p>
            </div>

            <div className="col-lg-4 col-md-6 item-bx">
              <h2 className="custom-font numb">03</h2>
              <h6 className="mb-20">Landscape Design</h6>
              <p>
                The purpose of adding a green space to a design doesn’t end with
                visual appeal. It improves the quality of life and amplifies the
                soul. Psychologically, greenery gives a positive vibe and helps
                in healing.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="portfolio section-padding">
        <div className="container-fluid">
          <div className="container">
            <div className="section-head text-center">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-8 col-sm-10">
                  <h6
                    className="custom-font wow fadeInDown"
                    data-wow-delay=".3s"
                  >
                    Best Works
                  </h6>
                  <h4 className="playfont wow flipInX" data-wow-delay=".5s">
                    Our Portfolio
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="gallery twsty inf-lit full-width">
              {_.map(filterPortfolio, (item, index) => {
                return (
                  <div
                    className={
                      "items two-column mt-50 wow fadeInUp " + item.type
                    }
                    data-wow-delay=".3s"
                    key={index}
                  >
                    <div
                      className="item-img bg-img wow imago"
                      data-background={item.cover}
                    >
                      <a href={`/portfolio/${item.type}/${item.slug}`}>
                        <div className="item-img-overlay">
                          <div className="location">
                            <h6>{item.location}</h6>
                            <h6>{item.builduparea}</h6>
                          </div>
                          <div className="status">
                            <h6>{item.status}</h6>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="info mt-10">
                      <h5>{item.title}</h5>
                      <span>{item.subtitle}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="text-center">
            <a href="/portfolio" className="btn-curve btn-color mt-50">
              <span>View All</span>
            </a>
          </div>
        </div>
      </section>

      <section
        className="skills-circle section-padding bg-img parallaxie"
        data-background="/img/1.jpg"
        data-overlay-dark="7"
      >
        <div className="container">
          <div className="section-head text-center">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10">
                <h6 className="custom-font wow fadeInDown" data-wow-delay=".3s">
                  process
                </h6>
                <h4 className="playfont wow flipInX" data-wow-delay=".5s">
                  TASK MANAGEMENT
                </h4>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-delay=".3s">
            <div className="col-lg-3 col-md-6">
              <div className="item text-center">
                <div className="skill" data-value="0.2">
                  <span className="custom-font">20%</span>
                  <h6>Requirement analysis</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item text-center">
                <div className="skill" data-value="0.40">
                  <span className="custom-font">40%</span>
                  <h6>Conceptualization</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item text-center">
                <div className="skill" data-value="0.70">
                  <span className="custom-font">70%</span>
                  <h6>Design development</h6>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="item text-center">
                <div className="skill" data-value="1">
                  <span className="custom-font">100%</span>
                  <h6>Implementation</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="team section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="tit">
                <h6
                  className="custom-font mb-10 wow fadeInDown"
                  data-wow-delay=".3s"
                >
                  Some thoughts from Us
                </h6>
                <h3 className="wow flipInX" data-wow-delay=".5s">
                  Our Team
                </h3>
                <p className="wow fadeInUp" data-wow-delay=".3s">
                  We are a team of diverse minds that are connected by the
                  values of teamwork, creativity and commitment who believe that
                  there is always room for improvement. We believe that the key
                  to success is a collective effort.
                </p>
                <div className="navs mt-30 wow fadeInUp" data-wow-delay=".3s">
                  <span className="prev">
                    <i className="fas fa-long-arrow-alt-left"></i>
                  </span>
                  <span className="next">
                    <i className="fas fa-long-arrow-alt-right"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="team-container">
                <div className="item wow fadeInUp" data-wow-delay=".3s">
                  <div className="img wow imago">
                    <img src="/img/team/paramesh.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h5>Paramesh</h5>
                    <span>Principal Architect</span>
                  </div>
                </div>
				<div className="item wow fadeInUp" data-wow-delay=".3s">
                  <div className="img wow imago">
                    <img src="/img/team/monisha.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h5>Monisha</h5>
                    <span>Associate Architect </span>
                  </div>
                </div>
				<div className="item wow fadeInUp" data-wow-delay=".3s">
                  <div className="img wow imago">
                    <img src="/img/team/kumar.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h5>Kumar</h5>
                    <span>Senior Architect</span>
                  </div>
                </div>
				<div className="item wow fadeInUp" data-wow-delay=".3s">
                  <div className="img wow imago">
                    <img src="/img/team/keerthana.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h5>Keerthana</h5>
                    <span>Interior Designer</span>
                  </div>
                </div>
                <div className="item wow fadeInUp" data-wow-delay=".3s">
                  <div className="img wow imago">
                    <img src="/img/team/sudangan.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h5>Sudangan</h5>
                    <span>Civil Engineer</span>
                  </div>
                </div>
               <div className="item wow fadeInUp" data-wow-delay=".3s">
                  <div className="img wow imago">
                    <img src="/img/team/vishnu.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h5>Vishnu</h5>
                    <span>Architect</span>
                  </div>
                </div>
                <div className="item wow fadeInUp" data-wow-delay=".3s">
                  <div className="img wow imago">
                    <img src="/img/team/prabhu.jpg" alt="" />
                  </div>
                  <div className="info">
                    <h5>Prabhu</h5>
                    <span>3D Visualiser</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dots-half bg-img bg-repeat"
          data-background="/img/dots.png"
        ></div>
      </section>

      <section className="contact cont-map">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-5 col-md-6 contact-form wow fadeInDown"
              data-wow-delay=".3s">
 
<iframe src="https://lead.noeticarchitects.com/form/" title="Enquiry" id="lead" name="form" scrolling="no" marginHeight="0px" marginWidth="0px" height="740px" width="475px" allowFullScreen></iframe>
            </div>
          </div>
        </div>
        <div
          className="bg-img"
          data-background="/img/contact/background.jpg"
        ></div>
      </section>
    </>
  );
};

export default Index;
