import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer dark">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="item abot">
              <div className="logo-footer mb-20">
                <a href="#0" className="logo">
                  <img src="/img/logo/noeticarchitects-black.png" alt="" />
                </a>
              </div>
              <p>
                Quality is of utmost importance to both the designers and the
                end users and we stand by it. We promise you an excellent design
                service and satisfaction.
              </p>
            </div>
          </div>
          <div className="col-lg-2 offset-lg-1">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Call</h6>
              </div>
              <p>
                <a href="tel:917339477963">+91 73394 77963</a>
              </p>
              <p>
                <a href="tel:918838567317">+91 88385 67317</a>
              </p>
            </div>
          </div>
          <div className="col-lg-2 offset-lg-1">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Write</h6>
              </div>
              <p>
                <a href="mailto:info@noeticarchitects.com">
                  info@noeticarchitects.com
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-2 offset-lg-1">
            <div className="item fotcont">
              <div className="fothead">
                <h6>Visit</h6>
              </div>
              <p>#133/2, 13th main, </p>
              <p>3rd cross, H.A.L 3rd stage, </p> <p>Kodihalli, Bangalore,</p>
              <p>Pin: 560 008.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="sub-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="text-left">
                <p>© 2021, Noetic Architects.</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="text-right">
                <p>All right reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
