import { createSlugify } from "../utils";

export const portfolio = [
  // Architecture
  {
    slug: createSlugify("JSL Apartment"),
    title: "JSL Apartment",
    subtitle: "Apartment renovation/ Architecture",
    cover: "/img/portfolio/architecture/bw/JSLApartmentbw.jpg",
    type: "architecture",
    location: "Richmond town-Bangalore",
    builduparea: "16,700 sft",
    status: "Ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/JSLApartment/1.jpg",
      "/img/portfolio/architecture/JSLApartment/2.jpg",
      "/img/portfolio/architecture/JSLApartment/3.jpg",
      "/img/portfolio/architecture/JSLApartment/4.jpg",
      "/img/portfolio/architecture/JSLApartment/5.jpg",
    ],
    intro: "",
    tags: ["contemporary", "Louvres", "Landscape"],
  },
  {
    slug: createSlugify("Bio-Farm House"),
    title: "Bio-Farm House",
    subtitle: "Farm House/Architecture",
    cover: "/img/portfolio/architecture/bw/BioFarmHousebw.jpg",
    type: "architecture",
    location: "Nandi hills-Bangalore",
    builduparea: "11800 SFT",
    status: "Completed",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/BioFarmHouse/1.jpg",
      "/img/portfolio/architecture/BioFarmHouse/2.jpg",
      "/img/portfolio/architecture/BioFarmHouse/3.jpg",
      "/img/portfolio/architecture/BioFarmHouse/4.jpeg",
      "/img/portfolio/architecture/BioFarmHouse/5.jpeg",
      "/img/portfolio/architecture/BioFarmHouse/6.jpeg",
      "/img/portfolio/architecture/BioFarmHouse/7.jpeg",
      "/img/portfolio/architecture/BioFarmHouse/8.jpeg",
    ],
    intro: "",
    tags: ["Modern", "cantilever", "cuboid"],
  },
  {
    slug: createSlugify("Royal Towers"),
    title: "Royal Towers",
    subtitle: "Commercial /Architecture",
    cover: "/img/portfolio/architecture/bw/RoyalTowersbw.jpg",
    type: "architecture",
    location: "Madurai",
    builduparea: "9900 sft",
    status: "Completed",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/RoyalTowers/1.jpg",
      "/img/portfolio/architecture/RoyalTowers/2.jpg",
      "/img/portfolio/architecture/RoyalTowers/3.jpg",
      "/img/portfolio/architecture/RoyalTowers/4.jpeg",
    ],
    intro: "",
    tags: ["Commercial", "Contemporary", "Terrace landscape"],
  },
  {
    slug: createSlugify("Courtyard house"),
    title: "Courtyard house",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/Courtyardhousebw.jpg",
    type: "architecture",
    location: "Salem",
    builduparea: "5975 sft",
    status: "Ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/Courtyardhouse/1.jpg",
      "/img/portfolio/architecture/Courtyardhouse/2.jpg",
      "/img/portfolio/architecture/Courtyardhouse/3.jpg",
      "/img/portfolio/architecture/Courtyardhouse/4.jpg",
      "/img/portfolio/architecture/Courtyardhouse/5.jpg",
      "/img/portfolio/architecture/Courtyardhouse/6.jpg",
      "/img/portfolio/architecture/Courtyardhouse/7.jpg",
      "/img/portfolio/architecture/Courtyardhouse/8.jpg",
      "/img/portfolio/architecture/Courtyardhouse/9.jpg",
    ],
    intro: "",
    tags: ["contemporary", "exposed concrete", "Perforated pattern", "Courtyard effect"],
  },
  {
    slug: createSlugify("Cuboid house"),
    title: "Cuboid house",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/Cuboidhousebw.jpg",
    type: "architecture",
    location: "Trichy",
    builduparea: "2900 sft",
    status: "Completed",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/Cuboidhouse/1.jpg",
      "/img/portfolio/architecture/Cuboidhouse/2.jpg",
      "/img/portfolio/architecture/Cuboidhouse/3.jpg",
      "/img/portfolio/architecture/Cuboidhouse/4.jpg",
      "/img/portfolio/architecture/Cuboidhouse/5.jpg",
      "/img/portfolio/architecture/Cuboidhouse/6.jpg",
      "/img/portfolio/architecture/Cuboidhouse/7.jpg",
      "/img/portfolio/architecture/Cuboidhouse/8.jpg",
      "/img/portfolio/architecture/Cuboidhouse/9.jpeg",
      "/img/portfolio/architecture/Cuboidhouse/10.jpg",
    ],
    intro: "",
    tags: ["Interlock", "Lego", "Geometric", "Minimalist"],
  },
  {
    slug: createSlugify("Jutting house"),
    title: "Jutting house",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/Juttinghousebw.jpg",
    type: "architecture",
    location: "Chennai",
    builduparea: "1890 sft",
    status: "Ongoing",
    homepage: true,
    gallery: [
      "/img/portfolio/architecture/Juttinghouse/1.jpg",
      "/img/portfolio/architecture/Juttinghouse/2.jpg",
      "/img/portfolio/architecture/Juttinghouse/3.jpg",
      "/img/portfolio/architecture/Juttinghouse/4.jpg",
      "/img/portfolio/architecture/Juttinghouse/5.jpg",
      "/img/portfolio/architecture/Juttinghouse/6.jpg",
      "/img/portfolio/architecture/Juttinghouse/7.jpg",
    ],
    intro: "",
    tags: ["Deconstructive", "Sustainable", "Modern", "Sustainable"],
  },
  {
    slug: createSlugify("Wooden box"),
    title: "Wooden box",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/Woodenboxbw.jpg",
    type: "architecture",
    location: "Karur",
    builduparea: "2350 sft",
    status: "Upcoming",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/Woodenbox/1.jpg",
      "/img/portfolio/architecture/Woodenbox/2.jpg",
      "/img/portfolio/architecture/Woodenbox/3.jpg",
      "/img/portfolio/architecture/Woodenbox/4.jpg",
      "/img/portfolio/architecture/Woodenbox/5.jpg",
      "/img/portfolio/architecture/Woodenbox/6.jpg",
      "/img/portfolio/architecture/Woodenbox/7.jpg",
    ],
    intro: "",
    tags: ["Modern architecture", "Wooden cladding", "Exposed concrete", "minimal"],
  },
  {
    slug: createSlugify("Streak lines"),
    title: "Streak lines",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/Streaklinesbw.jpg",
    type: "architecture",
    location: "Salem",
    builduparea: "3890 sft",
    status: "ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/Streaklines/1.jpg",
      "/img/portfolio/architecture/Streaklines/2.jpg",
      "/img/portfolio/architecture/Streaklines/3.jpg",
      "/img/portfolio/architecture/Streaklines/4.jpg",
      "/img/portfolio/architecture/Streaklines/5.jpg",
      "/img/portfolio/architecture/Streaklines/6.jpg",           
    ],
    intro: "",
    tags: ["Horizontal massing", "Sustainable", "Glass roofing"],
  },
  {
    slug: createSlugify("paster's House"),
    title: "paster's House",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/pasterbw.jpg",
    type: "architecture",
    location: "Chennai",
    builduparea: "3250 sft",
    status: "Completed",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/paster/1.jpg",
      "/img/portfolio/architecture/paster/2.jpg",
      "/img/portfolio/architecture/paster/3.jpeg",
      "/img/portfolio/architecture/paster/4.jpeg",
      "/img/portfolio/architecture/paster/5.jpeg",
    ],
    intro: "",
    tags: ["Wooden cladding", "Interlock", "Wooden strips"],
  },
  {
    slug: createSlugify("Lucid eye"),
    title: "Lucid eye",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/Lucideyebw.jpg",
    type: "architecture",
    location: "Karur",
    builduparea: "1650 sft",
    status: "Completed",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/Lucideye/1.jpg",
      "/img/portfolio/architecture/Lucideye/2.jpg",
      "/img/portfolio/architecture/Lucideye/3.jpeg",
      "/img/portfolio/architecture/Lucideye/4.jpeg",
    ],
    intro: "",
    tags: ["Art deco", "Perforated screen", "Colour perception"],
  },
  {
    slug: createSlugify("Sassy house"),
    title: "Sassy house",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/bw/Sassyhousebw.jpg",
    type: "architecture",
    location: "Madurai",
    builduparea: "2970 sft",
    status: "Upcoming",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/Sassyhouse/1.jpg",
      "/img/portfolio/architecture/Sassyhouse/2.jpg",
      "/img/portfolio/architecture/Sassyhouse/3.jpg",
      "/img/portfolio/architecture/Sassyhouse/4.jpg",
      "/img/portfolio/architecture/Sassyhouse/5.jpg",
      "/img/portfolio/architecture/Sassyhouse/6.jpg",
    ],
    intro: "",
    tags: ["Intersection", "Vertical", "Wooden cladding"],
  },
	{
    slug: createSlugify("Subramani Residence"),
    title: "Subramani Residence",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/Subramani/n2.jpg",
    type: "architecture",
    location: "Karur",
    builduparea: "1968  sft",
    status: "Ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/Subramani/n2.jpg",
	  "/img/portfolio/architecture/Subramani/n1.jpg",
	  "/img/portfolio/architecture/Subramani/e4.jpg",
	  "/img/portfolio/architecture/Subramani/e3.jpg",
    ],
    intro: "",
    tags: ["Intersection", "Vertical", "Wooden cladding"],
  },
	{
    slug: createSlugify("Le Rouge"),
    title: "Le Rouge",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/LeRouge/E1.jpg",
    type: "architecture",
    location: "Karur",
    builduparea: "1968  sft",
    status: "Ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/architecture/LeRouge/E1.jpg",
	  "/img/portfolio/architecture/LeRouge/E2.jpg",
	  "/img/portfolio/architecture/LeRouge/E3.jpg",
    ],
    intro: "",
    tags: ["Intersection", "Vertical", "Wooden cladding"],
  },
	{
    slug: createSlugify("Sudha Residence"),
    title: "Sudha Residence",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/Sudha/4.jpg",
    type: "architecture",
    location: "Bangalore",
    builduparea: "3627 sft",
    status: "Upcoming",
    homepage: true,
    gallery: [
      "/img/portfolio/architecture/Sudha/4.jpg",
    ],
    intro: "",
    tags: ["Intersection", "Vertical", "Wooden cladding"],
  },
	{
    slug: createSlugify("Canterbury Castle Club House"),
    title: "Canterbury Castle Club House",
    subtitle: "Club House/Architecture",
    cover: "/img/portfolio/architecture/Canterburycastle/4.jpg",
    type: "architecture",
    location: "Bangalore",
    builduparea: "22034 sft",
    status: "Upcoming",
    homepage: true,
    gallery: [
      "/img/portfolio/architecture/Canterburycastle/1.jpg",
	  "/img/portfolio/architecture/Canterburycastle/2.jpg",
      "/img/portfolio/architecture/Canterburycastle/3.jpg",	
	  "/img/portfolio/architecture/Canterburycastle/4.jpg",
	  "/img/portfolio/architecture/Canterburycastle/5.jpg",
	  "/img/portfolio/architecture/Canterburycastle/7.jpg",
	  "/img/portfolio/architecture/Canterburycastle/8.jpg",
      "/img/portfolio/architecture/Canterburycastle/9.jpg",	
	  "/img/portfolio/architecture/Canterburycastle/10.jpg",
	  "/img/portfolio/architecture/Canterburycastle/11.jpg",
    ],
    intro: "",
    tags: ["Intersection", "Vertical", "Wooden cladding"],
  },
	{
    slug: createSlugify("Lagom – The farm house"),
    title: "Lagom – The farm house",
    subtitle: "Resident/Architecture",
    cover: "/img/portfolio/architecture/Lagom/E1.jpg",
    type: "architecture",
    location: "Coimbatore",
    builduparea: "7861 sft",
    status: "Upcoming",
    homepage: true,
    gallery: [
      "/img/portfolio/architecture/Lagom/E1.jpg",
	  "/img/portfolio/architecture/Lagom/E2.jpg",
    ],
    intro: "",
    tags: ["Intersection", "Vertical", "Wooden cladding"],
  },
	
  // Interior
  {
    slug: createSlugify("Mr.Arvind nair"),
    title: "Mr.Arvind nair",
    subtitle: "Row house/Interior",
    cover: "/img/portfolio/interior/BW/Arvindnair.jpg",
    type: "interior",
    location: "Samrudhi Mystic Wind-Bangalore",
    builduparea: "3BHK-1565 sft",
    status: "Upcoming",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/Arvindnair/1.jpg",
      "/img/portfolio/interior/Arvindnair/2.jpg",
      "/img/portfolio/interior/Arvindnair/3.jpg",
      "/img/portfolio/interior/Arvindnair/4.jpg",
      "/img/portfolio/interior/Arvindnair/5.jpg",
      "/img/portfolio/interior/Arvindnair/6.jpg",
      "/img/portfolio/interior/Arvindnair/7.jpg",
      "/img/portfolio/interior/Arvindnair/8.jpg",
      "/img/portfolio/interior/Arvindnair/9.jpg",
      "/img/portfolio/interior/Arvindnair/10.jpg",
      "/img/portfolio/interior/Arvindnair/11.jpg",
      "/img/portfolio/interior/Arvindnair/12.jpg",
      "/img/portfolio/interior/Arvindnair/13.jpg",
    ],
    intro: "",
    tags: ["Contemporary", "Classic", "Perforated partition"],
  },
  {
    slug: createSlugify("Mr.Jagannath"),
    title: "Mr.Jagannath",
    subtitle: "Individual house /Interior",
    cover: "/img/portfolio/interior/BW/JAGANNATH.jpg",
    type: "interior",
    location: "Maruthi Layout- Bangalore",
    builduparea: "3BHK-1940 sft",
    status: "Ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/JAGANNATH/1.jpg",
      "/img/portfolio/interior/JAGANNATH/2.jpg",
      "/img/portfolio/interior/JAGANNATH/3.jpg",
      "/img/portfolio/interior/JAGANNATH/4.jpg",
      "/img/portfolio/interior/JAGANNATH/5.jpg",
      "/img/portfolio/interior/JAGANNATH/6.jpg",
      "/img/portfolio/interior/JAGANNATH/7.jpg",
      "/img/portfolio/interior/JAGANNATH/8.jpg",
      "/img/portfolio/interior/JAGANNATH/9.jpg",
      "/img/portfolio/interior/JAGANNATH/10.jpg",
      "/img/portfolio/interior/JAGANNATH/11.jpg",
      "/img/portfolio/interior/JAGANNATH/13.jpg",
      "/img/portfolio/interior/JAGANNATH/14.jpg",
      "/img/portfolio/interior/JAGANNATH/15.jpg",
      "/img/portfolio/interior/JAGANNATH/16.jpg",
      "/img/portfolio/interior/JAGANNATH/17.jpg",
    ],
    intro: "",
    tags: ["Classical", "Wooden partition", "Textured wall"],
  },
  {
    slug: createSlugify("Mr.Moorthi"),
    title: "Mr.Moorthi",
    subtitle: "Villa /Interior",
    cover: "/img/portfolio/interior/BW/Moorthi.jpg",
    type: "interior",
    location: "Varthur road - Bangalore",
    builduparea: "3BHK-2230 sft",
    status: "Completed",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/Moorthi/1.jpg",
      "/img/portfolio/interior/Moorthi/2.jpg",
      "/img/portfolio/interior/Moorthi/3.jpg",
      "/img/portfolio/interior/Moorthi/4.jpg",
      "/img/portfolio/interior/Moorthi/5.jpg",
      "/img/portfolio/interior/Moorthi/6.jpg",
      "/img/portfolio/interior/Moorthi/7.jpg",
      "/img/portfolio/interior/Moorthi/8.jpg",
      "/img/portfolio/interior/Moorthi/9.jpg",
      "/img/portfolio/interior/Moorthi/10.jpg",
    ],
    intro: "",
    tags: ["Urban modern", "minimalist, contemporary"],
  },
  {
    slug: createSlugify("Mr.Sudeep"),
    title: "Mr.Sudeep",
    subtitle: "Apartment /Interior",
    cover: "/img/portfolio/interior/BW/Sudeep.jpg",
    type: "interior",
    location: "Klassik Landmark- Bangalore",
    builduparea: "3BHK-2200 sft",
    status: "Upcoming",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/Sudeep/1.jpg",
      "/img/portfolio/interior/Sudeep/2.jpg",
      "/img/portfolio/interior/Sudeep/3.jpg",
      "/img/portfolio/interior/Sudeep/4.jpg",
      "/img/portfolio/interior/Sudeep/5.jpg",
      "/img/portfolio/interior/Sudeep/6.jpg",
      "/img/portfolio/interior/Sudeep/7.jpg",
      "/img/portfolio/interior/Sudeep/8.jpg",
      "/img/portfolio/interior/Sudeep/9.jpg",
      "/img/portfolio/interior/Sudeep/10.jpg",
      "/img/portfolio/interior/Sudeep/11.jpg",
      "/img/portfolio/interior/Sudeep/12.jpg",
      "/img/portfolio/interior/Sudeep/13.jpg",
      "/img/portfolio/interior/Sudeep/14.jpg",
      "/img/portfolio/interior/Sudeep/15.jpg",
      "/img/portfolio/interior/Sudeep/16.jpg",
      "/img/portfolio/interior/Sudeep/17.jpg",
      "/img/portfolio/interior/Sudeep/18.jpg",
      "/img/portfolio/interior/Sudeep/19.jpg",
      "/img/portfolio/interior/Sudeep/20.jpg",
      "/img/portfolio/interior/Sudeep/21.jpg",
      "/img/portfolio/interior/Sudeep/22.jpg",
    ],
    intro: "",
    tags: ["Modern", "Nautical", "Minimal"],
  },
  {
    slug: createSlugify("Mr.Prabhakar"),
    title: "Mr.Prabhakar",
    subtitle: "Apartment /Interior",
    cover: "/img/portfolio/interior/BW/Prabhakar.jpg",
    type: "interior",
    location: "HSR-Layout- Bangalore",
    builduparea: "3BHK-1250 sft",
    status: "Completed",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/Prabhakar/1.jpg",
      "/img/portfolio/interior/Prabhakar/2.jpg",
      "/img/portfolio/interior/Prabhakar/3.jpg",
      "/img/portfolio/interior/Prabhakar/4.jpg",
      "/img/portfolio/interior/Prabhakar/5.jpg",
      "/img/portfolio/interior/Prabhakar/6.jpg",
      "/img/portfolio/interior/Prabhakar/7.jpg",
      "/img/portfolio/interior/Prabhakar/8.jpg",
    ],
    intro: "",
    tags: ["Eclectic", "Contrast", "Simplicity"],
  },
  {
    slug: createSlugify("Mr.Vijay"),
    title: "Mr.Vijay",
    subtitle: "Apartment / Interior",
    cover: "/img/portfolio/interior/BW/Vijay.jpg",
    type: "interior",
    location: "Prestige Song of south- Bangalore",
    builduparea: "3BHK-1360 sft",
    status: "Upcoming",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/Vijay/1.jpg",
      "/img/portfolio/interior/Vijay/2.jpg",
      "/img/portfolio/interior/Vijay/3.jpg",
      "/img/portfolio/interior/Vijay/4.jpg",
      "/img/portfolio/interior/Vijay/5.jpg",
      "/img/portfolio/interior/Vijay/6.jpg",
      "/img/portfolio/interior/Vijay/7.jpg",
      "/img/portfolio/interior/Vijay/8.jpg",
      "/img/portfolio/interior/Vijay/9.jpg",
      "/img/portfolio/interior/Vijay/10.jpg",
      "/img/portfolio/interior/Vijay/11.jpg",
      "/img/portfolio/interior/Vijay/12.jpg",
    ],
    intro: "",
    tags: ["Scandinavian", "mid-century", "Minimalist"],
  },
  {
    slug: createSlugify("Mr.Vikash Agarwal"),
    title: "Mr.Vikash Agarwal",
    subtitle: "Apartment / Interior",
    cover: "/img/portfolio/interior/BW/Vikashagarwal.jpg",
    type: "interior",
    location: "Prestige Song of south- Bangalore",
    builduparea: "3BHK-1450 sft",
    status: "Ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/Vikashagarwal/1.jpg",
      "/img/portfolio/interior/Vikashagarwal/2.jpg",
      "/img/portfolio/interior/Vikashagarwal/3.jpg",
      "/img/portfolio/interior/Vikashagarwal/4.jpg",
      "/img/portfolio/interior/Vikashagarwal/5.jpg",
      "/img/portfolio/interior/Vikashagarwal/6.jpg",
      "/img/portfolio/interior/Vikashagarwal/7.jpg",
      "/img/portfolio/interior/Vikashagarwal/8.jpg",
      "/img/portfolio/interior/Vikashagarwal/9.jpg",
      "/img/portfolio/interior/Vikashagarwal/10.jpg",
      "/img/portfolio/interior/Vikashagarwal/11.jpg",
      "/img/portfolio/interior/Vikashagarwal/12.jpg",
      "/img/portfolio/interior/Vikashagarwal/13.jpg",
    ],
    intro: "",
    tags: ["Urban modern", "Complementary traits", "Edgy"],
  },
	{
    slug: createSlugify("Streak lines"),
    title: "Streak lines",
    subtitle: "Resident/Interior",
    cover: "/img/portfolio/interior/Streaklines/MBR1.jpg",
    type: "interior",
    location: "Salem",
    builduparea: "3890 sft",
    status: "ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/interior/Streaklines/dining.jpg",
		"/img/portfolio/interior/Streaklines/foyer.jpg",
		"/img/portfolio/interior/Streaklines/foyer1.jpg",
		"/img/portfolio/interior/Streaklines/GBR.jpg",
		"/img/portfolio/interior/Streaklines/GBR1.jpg",
		"/img/portfolio/interior/Streaklines/kitchen3.jpg",
		"/img/portfolio/interior/Streaklines/MBR1.jpg",
		"/img/portfolio/interior/Streaklines/MBR2.jpg",
		"/img/portfolio/interior/Streaklines/pbr1.jpg",
		"/img/portfolio/interior/Streaklines/pbr2.jpg",
		"/img/portfolio/interior/Streaklines/pooja.jpg",
		"/img/portfolio/interior/Streaklines/pooja1.jpg",
		"/img/portfolio/interior/Streaklines/SBR.jpg",
		"/img/portfolio/interior/Streaklines/SBR1.jpg",
		"/img/portfolio/interior/Streaklines/SBR2.jpg",
		"/img/portfolio/interior/Streaklines/toilet.jpg",         
    ],
    intro: "",
    tags: ["Horizontal massing", "Sustainable", "Glass roofing"],
  },
	{
    slug: createSlugify("Courtyard house"),
    title: "Courtyard house",
    subtitle: "Resident/Interior",
    cover: "/img/portfolio/interior/Courtyard/1.jpg",
    type: "interior",
    location: "Salem",
    builduparea: "5975 sft",
    status: "upcoming",
    homepage: false,
    gallery: [
       "/img/portfolio/interior/Courtyard/4.jpg",
"/img/portfolio/interior/Courtyard/1.jpg",
"/img/portfolio/interior/Courtyard/2.jpg",
"/img/portfolio/interior/Courtyard/3.jpg",
"/img/portfolio/interior/Courtyard/5.jpg",
"/img/portfolio/interior/Courtyard/6.jpg",
"/img/portfolio/interior/Courtyard/7a.jpg",
"/img/portfolio/interior/Courtyard/7b.jpg",
"/img/portfolio/interior/Courtyard/8.jpg",
"/img/portfolio/interior/Courtyard/9.jpg",
"/img/portfolio/interior/Courtyard/10.jpg",
"/img/portfolio/interior/Courtyard/11.jpg",
"/img/portfolio/interior/Courtyard/11a.jpg",
"/img/portfolio/interior/Courtyard/11b.jpg",
"/img/portfolio/interior/Courtyard/12a.jpg",
"/img/portfolio/interior/Courtyard/12b.jpg",       
    ],
    intro: "",
    tags: ["Horizontal massing", "Sustainable", "Glass roofing"],
  },
	{
    slug: createSlugify("Pandey Residence "),
    title: "Pandey Residence ",
    subtitle: "Resident/Interior",
    cover: "/img/portfolio/interior/Pandey/BED1-1.jpg",
    type: "interior",
    location: "Bangalore",
    builduparea: "3527 sft",
    status: "upcoming",
    homepage: false,
    gallery: [
       "/img/portfolio/interior/Pandey/BED1-1.jpg",
"/img/portfolio/interior/Pandey/BED1-2.jpg",
"/img/portfolio/interior/Pandey/BED2-1.jpg",
"/img/portfolio/interior/Pandey/BED3-1.jpg",
"/img/portfolio/interior/Pandey/BED5-1.jpg",
"/img/portfolio/interior/Pandey/FL1.jpg",
"/img/portfolio/interior/Pandey/FL2.jpg",
"/img/portfolio/interior/Pandey/GYM 1.jpg",
"/img/portfolio/interior/Pandey/KITCHEN3.jpg",
"/img/portfolio/interior/Pandey/LIVING.jpg",
"/img/portfolio/interior/Pandey/LIVING2.jpg",     
    ],
    intro: "",
    tags: ["Horizontal massing", "Sustainable", "Glass roofing"],
  },
	{
    slug: createSlugify("Kishore Residence "),
    title: "Kishore Residence ",
    subtitle: "Resident/Interior",
    cover: "/img/portfolio/interior/Kishore/D1.jpg",
    type: "interior",
    location: "Bangalore",
    builduparea: "2914  sft",
    status: "ongoing",
    homepage: false,
    gallery: [
       
"/img/portfolio/interior/Kishore/D1.jpg",
"/img/portfolio/interior/Kishore/GBR1.jpg",
"/img/portfolio/interior/Kishore/GBR2.jpg",
"/img/portfolio/interior/Kishore/K2.jpg",
"/img/portfolio/interior/Kishore/L1.jpg",
"/img/portfolio/interior/Kishore/MBR1.jpg",
"/img/portfolio/interior/Kishore/MBR2.jpg",
"/img/portfolio/interior/Kishore/PBR1.jpg",
"/img/portfolio/interior/Kishore/PBR2.jpg",
    ],
    intro: "",
    tags: ["Horizontal massing", "Sustainable", "Glass roofing"],
  },
  // Landscape
  {
    slug: createSlugify("Mrs.Kanchana"),
    title: "Mrs.Kanchana",
    subtitle: "Terrace garden/Landscape",
    cover: "/img/portfolio/landscape/BW/Kanchana.jpg",
    type: "landscape",
    location: "Richmond town- Bangalore",
    builduparea: "450 sft",
    status: "Ongoing",
    homepage: false,
    gallery: [
      "/img/portfolio/landscape/Kanchana/1.jpg",
      "/img/portfolio/landscape/Kanchana/2.jpg",
      "/img/portfolio/landscape/Kanchana/3.jpg",
      "/img/portfolio/landscape/Kanchana/4.jpg",
    ],
    intro: "",
    tags: ["Informal landscape", "Modern", "Elegant"],
  },
  {
    slug: createSlugify("Mr.Bhargav"),
    title: "Mr.Bhargav",
    subtitle: "Backyard/Landscape",
    cover: "/img/portfolio/landscape/BW/bhargav.jpg",
    type: "landscape",
    location: "Chikkaballapur-Bangalore",
    builduparea: "375 sft",
    status: "Ongoing",
    homepage: true,
    gallery: [
      "/img/portfolio/landscape/bhargav/1.jpg",
      "/img/portfolio/landscape/bhargav/2.jpg",
      "/img/portfolio/landscape/bhargav/3.jpg",
      "/img/portfolio/landscape/bhargav/4.jpg",
    ],
    intro: "",
    tags: ["Classic", "Indoor landscape", "Mixed use"],
  },
];
