import MainLayout from "./components/mainlayout";
import Home from "./pages";
import Portfolio from "./pages/portfolio";
import PortfolioDetails from "./pages/portfoliodetails";
import Contact from "./pages/contact";
import PageNotFound from "./pages/404";

export default [
  {
    path: "/contact",
    exact: true,
    layout: MainLayout,
    component: Contact,
  },
  {
    path: "/portfolio/:type/:title",
    exact: true,
    layout: MainLayout,
    component: PortfolioDetails,
  },
  {
    path: "/portfolio",
    exact: true,
    layout: MainLayout,
    component: Portfolio,
  },
  {
    path: "/",
    exact: true,
    layout: MainLayout,
    component: Home,
  },
  {
    path: "*",
    exact: true,
    layout: MainLayout,
    component: PageNotFound,
  },
];
