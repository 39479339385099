import React, { useState, useEffect } from "react";
import { portfolio } from "../data/portfolio";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import _ from "lodash";

const PortfolioDetails = (props) => {
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [portfolioData, setportfolioData] = useState({});
  const [images, setimages] = useState([]);

  const fetchPortfolio = () => {
    let portfolioData = _.find(
      portfolio,
      (obj) => `/portfolio/${obj.type}/${obj.slug}` === props.location.pathname
    );
    if (portfolioData === undefined) {
      window.location.href = "/portfolio";
    } else {
      setportfolioData(portfolioData);
      setimages(portfolioData.gallery);
    }
  };

  useEffect(() => {
    fetchPortfolio();
  });

  const previousProject = () => {
    let findIndex = _.findIndex(
      portfolio,
      (obj) => `/portfolio/${obj.type}/${obj.slug}` === props.location.pathname
    );
    let prevPortfolio =
      portfolio[findIndex === 0 ? portfolio.length - 1 : findIndex - 1];
    window.location.href = `/portfolio/${prevPortfolio.type}/${prevPortfolio.slug}`;
  };

  const nextProject = () => {
    let findIndex = _.findIndex(
      portfolio,
      (obj) => `/portfolio/${obj.type}/${obj.slug}` === props.location.pathname
    );
    let nextPortfolio =
      portfolio[findIndex === portfolio.length - 1 ? 0 : findIndex + 1];
    window.location.href = `/portfolio/${nextPortfolio.type}/${nextPortfolio.slug}`;
  };

  const openModal = (index) => {
    setphotoIndex(index);
    setisOpen(true);
  };

  return (
    <>
      <header
        className="pages-header bg-img valign"
        data-background={portfolioData.cover}
        data-overlay-dark="5"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cont text-center">
                <h1>{portfolioData.title}</h1>
                <div className="path">
                  <a href="/">Home</a>
                  <span>/</span>
                  <a href="/portfolio">Portfolio</a>
                  <span>/</span>
                  <a href="#0" className="active">
                    {portfolioData.title}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section className="intro-section section-padding">
        <div className="container">
          <div className="row">
            {portfolioData.intro && (
              <>
                <div className="col-lg-3 col-md-4">
                  <div className="htit">
                    <h4>introduction</h4>
                  </div>
                </div>
                <div className="col-lg-8 offset-lg-1 col-md-8 mb-30">
                  <div className="text">
                    <p>{portfolioData.intro}</p>
                  </div>
                </div>
              </>
            )}
            <div className="col-lg-3">
              <div className="item mt-30">
                <h6>Project Title</h6>
                <p>
                  <a href="#0">{portfolioData.title}</a>
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="item mt-30">
                <h6>Built-up area</h6>
                <p>{portfolioData.builduparea}</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="item mt-30">
                <h6>Location</h6>
                <p>
                  <a href="#0">{portfolioData.location}</a>
                </p>
              </div>
            </div>
            {portfolioData.tags && portfolioData.tags.length > 0 && (
              <div className="col-lg-3">
                <div className="item mt-30">
                  <h6>Tags</h6>
                  <p>
                    {_.map(portfolioData.tags, (item, index, array) => {
                      if (array.length - 1 === index) {
                        return (
                          <a href="#0" key={index}>
                            {item}
                          </a>
                        );
                      } else {
                        return (
                          <a href="#0" key={index}>
                            {`${item},\xa0`}
                          </a>
                        );
                      }
                    })}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="justified-gallery">
              {_.map(images, (item, index) => {
                return (
                  <a href="#0" onClick={() => openModal(index)} key={index}>
                    <img alt="" src={item} />
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="float-left proj-prev">
                <a href="#0" onClick={() => previousProject()}>
                  <i className="fa fa-chevron-left"></i> Previous Project
                </a>
              </div>
              <div className="float-right proj-next">
                <a href="#0" onClick={() => nextProject()}>
                  Next Project <i className="fa fa-chevron-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setisOpen(false)}
          onMovePrevRequest={() =>
            setphotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setphotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};

export default PortfolioDetails;
